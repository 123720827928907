/** @jsxImportSource @emotion/react */

'use client';

import { css, Theme } from '@emotion/react';
import { Menu, Close } from '@livechat/design-system-icons';
import { DesignToken, Icon } from '@livechat/design-system-react-components';
import localFont from 'next/font/local';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { LogoLanding as Logo } from '@teamchat-shared/components/Logo';
import Box from '@teamchat-shared/design/Box';
import Text from '@teamchat-shared/design/Text';
import { config } from '@teamchat-shared/lib/config';

import AIAliceImg from 'public/landing-page/ai-alice.png';
import AIJamesImg from 'public/landing-page/ai-james.png';
import AIKerryImg from 'public/landing-page/ai-kerry.png';
import appImg from 'public/landing-page/app.png';
import chatListImg from 'public/landing-page/chat-list.png';
import sidebarImg from 'public/landing-page/sidebar.png';
import sparklesImg from 'public/landing-page/sparkles.svg';

const colfax = localFont({
  src: [
    {
      path: '../../../shared/fonts/ColfaxWebRegular.woff2',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../../../shared/fonts/ColfaxWebRegularItalic.woff2',
      weight: 'normal',
      style: 'italic',
    },
    {
      path: '../../../shared/fonts/ColfaxWebBold.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../../../shared/fonts/ColfaxWebMedium.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
});

const buttonStyles = css`
  font-family: ${colfax.style.fontFamily}, sans-serif;
  color: inherit;
  display: inline-block;
  padding: 15px 15px 12px 15px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1;
  appearance: none;
  transition-duration: 0.2s;
  transition-property: opacity, border, color, background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  border: 1px solid transparent;

  &:hover {
    background-color: var(${DesignToken.BtnSecondaryBackgroundHover});
    border-color: var(${DesignToken.BtnSecondaryBackgroundHover});
  }
`;

const primaryButtonStyles = css`
  ${buttonStyles}
  color: var(${DesignToken.ContentInvertPrimary});
  border: 1px solid var(${DesignToken.DecorBlue600});
  background-color: var(${DesignToken.DecorBlue600});

  &:hover {
    background-color: var(${DesignToken.ActionPrimaryHover});
    border-color: var(${DesignToken.ActionPrimaryHover});
  }
`;

const inputStyles = css`
  font-family: ${colfax.style.fontFamily}, sans-serif;
  height: 46px;
  width: 100%;
  min-width: 280px;
  appearance: none;
  border: 0;
  padding: 15px;
  outline-style: solid;
  outline-width: 1px;
  outline-color: #a6a6a6;
  font-size: inherit;
  color: var(${DesignToken.DecorGray600});
  background-color: var(${DesignToken.ContentLockedWhite});
  border-radius: 12px;
  flex-grow: 1;
  display: block;
  border: 1px solid transparent;
  transition: 200ms border-color;
  line-height: 1.1em;
`;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LiveChatWidget: any;
  }
}

export default function Page() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = '';
      document.body.style.overflow = '';
    }
  }, [isMobileMenuOpen]);

  const getSignupUrl = () => {
    const signupUrl = new URL(`${config.accountsURL}/signup`);

    signupUrl.searchParams.set('client_id', config.clientId);
    signupUrl.searchParams.set('redirect_uri', `${config.appURL}/app`);
    signupUrl.searchParams.set('response_type', 'token');
    signupUrl.searchParams.set('source_id', 'signup-form');
    signupUrl.searchParams.set('source_type', 'website');
    signupUrl.searchParams.set('email', email);
    signupUrl.searchParams.set('landing_page', 'hello.text.com');

    return signupUrl.toString();
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    window.location.href = getSignupUrl();
  };

  return (
    <Box
      className={colfax.className}
      color={`var(${DesignToken.DecorGray900})`}
      css={css`
        *[class^='lc-Typography-module'] {
          font-family: ${colfax.style.fontFamily}, sans-serif;
        }
      `}
    >
      <Box as="header" position="sticky" zIndex="100" top="0">
        <Box
          maxWidth="1360px"
          m="0 auto"
          backgroundColor={`var(${DesignToken.ContentLockedWhite})`}
          p={{
            _: '20px 24px',
            md: '20px 50px',
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Box display="flex">
            <Logo height="32px" />
          </Box>

          <Box
            display={{
              _: 'block',
              md: 'none',
            }}
          >
            <button
              css={css`
                appearance: none;
                border: 0;
                background-color: transparent;
                font-size: inherit;
                font-family: inherit;
                color: inherit;
                text-decoration: none;
                padding: 0;
              `}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
              aria-expanded={isMobileMenuOpen}
              aria-haspopup
            >
              {isMobileMenuOpen ? (
                <Icon source={Close} size="xlarge" />
              ) : (
                <Icon source={Menu} size="xlarge" />
              )}
            </button>
          </Box>

          <Box
            display={{ _: 'none', md: 'flex' }}
            alignItems="center"
            justifyContent="space-between"
            width={1}
            ml="106px"
            css={css`
              ${isMobileMenuOpen &&
              `
                display: flex;
                flex-direction: column;
                position: fixed;
                top: 78px;
                bottom: 0;
                left: 0;
                border-bottom: 1px solid var(${DesignToken.BorderBasicDefault});
                padding-bottom: 10px;
                margin: 0;
                align-items: flex-start;
                background-color: var(${DesignToken.ContentLockedWhite});
              `}
            `}
          >
            <Box as="nav">
              <ul
                css={css`
                  display: flex;
                  gap: 30px;
                  margin: 0;
                  padding: 0;
                  align-items: center;
                  list-style-type: none;
                  font-weight: 500;

                  ${isMobileMenuOpen &&
                  `
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 0;
                      
                    `}
                `}
              >
                <li
                  css={css`
                    list-style-type: none;
                  `}
                >
                  <Link
                    href="/#product"
                    css={css`
                      ${isMobileMenuOpen &&
                      `
                        display: flex;
                        font-size: 24px;
                        font-weight: 500;
                        padding: 20px 24px;
                      `}
                    `}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Product
                  </Link>
                </li>
                <li>
                  <Link
                    href="/#pricing"
                    css={css`
                      ${isMobileMenuOpen &&
                      `
                      display: flex;
                        font-size: 24px;
                        font-weight: 500;
                        padding: 20px 24px;
                    `}
                    `}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Pricing
                  </Link>
                </li>

                <li>
                  <Link
                    href="https://teamchat.kb.help/"
                    target="_blank"
                    css={css`
                      ${isMobileMenuOpen &&
                      `
                      display: flex;
                        font-size: 24px;
                        font-weight: 500;
                        padding: 20px 24px;
                    `}
                    `}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Help
                  </Link>
                </li>
              </ul>
            </Box>

            <Box
              width={{
                _: '100%',
                md: 'auto',
              }}
            >
              {!isMobileMenuOpen ? (
                <ul
                  css={css`
                    display: flex;
                    gap: 12px;
                    margin: 0;
                    padding: 0;
                    align-items: center;
                    list-style-type: none;
                  `}
                >
                  <li>
                    <Link href="/app" css={buttonStyles}>
                      Log in
                    </Link>
                  </li>
                  <li>
                    <Link href={getSignupUrl()} css={primaryButtonStyles}>
                      Use for free
                    </Link>
                  </li>
                </ul>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="24px"
                  width={1}
                  p="24px"
                >
                  <Box>
                    <form name="signup" onSubmit={handleSubmit}>
                      <Box display="flex" flexDirection="column" gap="4px">
                        <input
                          type="email"
                          placeholder="Enter your business email"
                          name="email"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setEmail(event.target.value.trim())}
                          css={inputStyles}
                        />

                        <button type="submit" css={primaryButtonStyles}>
                          Create free account
                        </button>
                      </Box>
                    </form>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontSize="18px" lineHeight="1.55555556">
                      Already a customer?&nbsp;
                    </Text>
                    <Link
                      href="/app"
                      css={css`
                        font-size: 18px;
                        line-height: 1.55555556;
                        color: var(${DesignToken.ActionPrimaryDefault});
                      `}
                    >
                      Log in
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        height={{
          _: '60px',
          md: '76px',
        }}
        position="fixed"
        top={76}
        left={0}
        right={0}
        zIndex={1}
        css={css`
              background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--surface-primary-default) 100%);
          }
        `}
      />

      <Box
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '0 24px',
          md: '0 50px',
        }}
      >
        <h1
          css={(theme: Theme) => css`
            margin-top: 70px;
            font-size: 40px;
            line-height: 1.02777778;
            font-weight: 500;
            letter-spacing: -2px;
            max-width: 750px;
            text-align: center;

            @media (min-width: ${theme.breakpoints.md}) {
              font-size: 65px;
              text-align: left;
            }
          `}
        >
          A team chat app stripped down to essentials.
        </h1>

        <h2
          css={(theme: Theme) => css`
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.1px;
            line-height: 1.333;
            max-width: 520px;
            text-align: center;

            @media (min-width: ${theme.breakpoints.md}) {
              font-size: 23px;
              text-align: left;
            }
          `}
        >
          Designed for fast, clear text exchanges, making every chat count
          towards productivity.
        </h2>

        <Box mt="20px" display="flex" flexDirection="column">
          <form name="signup" onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="4px"
              flexDirection={{
                _: 'column',
                md: 'row',
              }}
              justifyContent={{
                _: 'center',
                md: 'flex-start',
              }}
            >
              <Box position="relative">
                <input
                  type="email"
                  placeholder="Enter your business email"
                  name="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value.trim())
                  }
                  css={inputStyles}
                />
              </Box>
              <button type="submit" css={primaryButtonStyles}>
                Create free account
              </button>
            </Box>
          </form>

          <Box
            mt="8px"
            width={{
              _: 'max-content',
              md: 'auto',
            }}
            css={css`
              font-size: 12px;
              line-height: 1.3;
            `}
          >
            Free forever, no credit card required
          </Box>
        </Box>
      </Box>

      <Box
        id="product"
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '0 24px 50px 24px',
          md: '0 50px 50px 50px',
        }}
      >
        <Box mt="50px" display="flex" justifyContent="center">
          <Image
            src={appImg}
            alt="App overview"
            width={1890}
            css={css`
              max-width: 100%;
              height: auto;
              border-radius: 15px;
              box-shadow: 0px 10.5px 60px 0px rgba(0, 0, 0, 0.25);
            `}
            quality={100}
            priority
          />
        </Box>
      </Box>

      <Box
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '50px 24px',
          md: '50px',
        }}
      >
        <Box
          display="flex"
          maxWidth={900}
          gap={{
            _: '10px',
            md: '50px',
          }}
          flexDirection={{
            _: 'column',
            md: 'row',
          }}
          px={0}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flexGrow={1}
            flexBasis="50%"
            pl={0}
            pr={0}
          >
            <Box mb={7}>
              <Text
                fontSize={{
                  _: '30px',
                  md: '48px',
                }}
                fontWeight={500}
                lineHeight="1.10714286"
                css={(theme: Theme) => css`
                  letter-spacing: -2px;
                  text-align: center;

                  @media (min-width: ${theme.breakpoints.md}) {
                    text-align: left;
                  }
                `}
              >
                Transparent
                <br /> communication
              </Text>
            </Box>

            <Box>
              <Text
                fontSize={{
                  _: '18px',
                  md: '22px',
                }}
                fontWeight={400}
                lineHeight="1.333"
                css={css`
                  letter-spacing: -0.1px;
                `}
                textAlign={{
                  _: 'center',
                  md: 'left',
                }}
              >
                Built on a foundation of transparency with no private channels
                and no exceptions, TeamChat ensures open communication and
                fosters a company culture where information is shared freely.
              </Text>
            </Box>
          </Box>

          <Box
            flexGrow={1}
            flexBasis="50%"
            display="flex"
            flexDirection="column"
            gap="64px"
          >
            <Image
              src={sidebarImg}
              alt="Sidebar"
              width={1046}
              css={css`
                max-width: 100%;
                height: auto;
              `}
              quality={100}
            />
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '50px 24px',
          md: '50px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          minWidth={{
            _: 'auto',
            md: '30%',
          }}
        >
          <Box
            maxWidth="380px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box mb={7} maxWidth="250px" position="relative">
              <Text
                fontSize={{
                  _: '30px',
                  md: '48px',
                }}
                fontWeight={500}
                lineHeight="1.10714286"
                textAlign="center"
                css={css`
                  letter-spacing: -2px;
                `}
              >
                Your focus is our focus
              </Text>

              <Box
                position="absolute"
                top={{
                  _: '-16px',
                  md: '-18px',
                }}
                right={{
                  _: '-20px',
                  md: '-32px',
                }}
                borderRadius="50%"
                backgroundColor="#EE0007"
                color="#fff"
                width={{
                  _: '36px',
                  md: '44px',
                }}
                height={{
                  _: '36px',
                  md: '44px',
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="0px 6px 20px 0px rgba(200, 49, 49, 0.40)"
              >
                <Text
                  fontSize={{
                    _: '25px',
                    md: '30px',
                  }}
                  lineHeight="1"
                  fontWeight="500"
                  css={css`
                    padding-top: 6px;
                  `}
                >
                  1
                </Text>
              </Box>
            </Box>

            <Box>
              <Text
                fontSize={{
                  _: '18px',
                  md: '22px',
                }}
                fontWeight={400}
                lineHeight="1.333"
                textAlign={{
                  _: 'center',
                }}
                css={css`
                  letter-spacing: -0.1px;
                `}
              >
                TeamChat nudges you to communicate only when it counts, keeping
                your team&apos;s chat focused and meaningful.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '50px 24px',
          md: '50px',
        }}
      >
        <Box
          display="flex"
          gap="50px"
          flexDirection={{
            _: 'column',
            md: 'row',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flexGrow={1}
            flexBasis="50%"
            pl={0}
            pr={0}
            gap="26px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{
                _: 'center',
                md: 'flex-start',
              }}
              position="relative"
              right={{
                _: '0',
                md: '25px',
              }}
            >
              <Image
                src={sparklesImg}
                alt="Sparkles image"
                width={26}
                css={css`
                  max-width: 100%;
                  height: auto;
                  position: relative;
                  bottom: 20px;
                `}
                quality={100}
              />

              <Box
                color={`var(${DesignToken.ContentInvertPrimary})`}
                borderRadius="10px"
                p="3px 5px 1px 5px"
                css={css`
                  font-size: 22px;
                  line-height: 23px;
                  font-weight: 500;
                  background: linear-gradient(127deg, #870aff, #5949e8);
                `}
                mr="6px"
              >
                AI
              </Box>

              <Box pt="4px">
                <Text fontSize="22px">Assistants</Text>
              </Box>
            </Box>

            <Box>
              <Text
                fontSize={{
                  _: '30px',
                  md: '48px',
                }}
                fontWeight={500}
                lineHeight="1.10714286"
                css={css`
                  letter-spacing: -2px;
                `}
                textAlign={{
                  _: 'center',
                  md: 'left',
                }}
              >
                Your team superpowered
              </Text>
            </Box>

            <Box>
              <Text
                fontSize={{
                  _: '18px',
                  md: '22px',
                }}
                fontWeight={400}
                lineHeight="1.333"
                css={css`
                  letter-spacing: -0.1px;
                `}
                textAlign={{
                  _: 'center',
                  md: 'left',
                }}
              >
                Your AI-teammates that seamlessly integrate into your team,
                taking over specific roles like designers or copywriters. They
                allow your team members to focus on strategic work instead of
                repeatable tasks.
              </Text>
            </Box>

            <Box>
              <Text
                fontSize={{
                  _: '18px',
                  md: '22px',
                }}
                fontWeight={400}
                lineHeight="1.333"
                css={css`
                  letter-spacing: -0.1px;
                `}
                textAlign={{
                  _: 'center',
                  md: 'left',
                }}
              >
                <Link
                  href="/app"
                  css={css`
                    color: var(${DesignToken.ActionPrimaryDefault});
                  `}
                >
                  See
                </Link>{' '}
                what AI-assistants can do for you.
              </Text>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{
              _: 'center',
              md: 'flex-start',
            }}
            flexGrow={1}
            flexBasis="50%"
            pl={0}
            pr={0}
            gap="30px"
            position="relative"
          >
            <Image
              src={AIJamesImg}
              alt="James AI Developer"
              width={590}
              css={(theme: Theme) => css`
                max-width: 100%;
                height: auto;
                box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
                border-radius: 26px;
                position: relative;
                left: 0px;
                width: 100%;

                @media (min-width: ${theme.breakpoints.md}) {
                  left: 5%;
                  width: auto;
                }
              `}
              quality={100}
            />

            <Image
              src={AIAliceImg}
              alt="Alice AI Designer"
              width={590}
              css={(theme: Theme) => css`
                max-width: 100%;
                height: auto;
                box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
                border-radius: 26px;
                position: relative;
                left: 0px;
                width: 100%;

                @media (min-width: ${theme.breakpoints.md}) {
                  left: 15%;
                  width: auto;
                }
              `}
              quality={100}
            />

            <Image
              src={AIKerryImg}
              alt="Kerry AI Designer"
              width={590}
              css={(theme: Theme) => css`
                max-width: 100%;
                height: auto;
                box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
                border-radius: 26px;
                left: 0;
                width: 100%;

                @media (min-width: ${theme.breakpoints.md}) {
                  left: 0;
                  width: auto;
                }
              `}
              quality={100}
            />
          </Box>
        </Box>
      </Box>

      <Box
        id="pricing"
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '50px 24px',
          md: '50px',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            mb={{
              _: '20px',
              md: '50px',
            }}
          >
            <Text
              fontSize={{
                _: '28px',
                md: '36px',
              }}
              fontWeight="500"
              lineHeight="1.15"
              letterSpacing="-.5px"
              textAlign={{
                _: 'center',
                md: 'left',
              }}
            >
              Pricing
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection={{
              _: 'column',
              md: 'row',
            }}
            borderRadius="20px"
            border="1px solid #c9c9c9"
            backgroundColor="#FBFBFB"
          >
            <Box
              flexBasis="50%"
              p={{
                _: '30px',
                md: '54px',
              }}
              display="flex"
              flexDirection="column"
              gap={{
                _: '20px',
                md: '40px',
              }}
            >
              <Box display="flex">
                <Text
                  fontSize={{
                    _: '90px',
                    md: '120px',
                  }}
                  fontWeight="500"
                  lineHeight="1"
                >
                  0
                </Text>

                <Text
                  fontSize={{
                    _: '30px',
                    md: '50px',
                  }}
                  fontWeight="700"
                  lineHeight="1"
                >
                  $
                </Text>
              </Box>

              <Box>
                <Text fontSize="20px" lineHeight="29px">
                  TeamChat is now available completely <strong>free</strong> of
                  charge. There are no additional costs, and you don&apos;t need
                  to provide your credit card details.
                </Text>
              </Box>

              <Box>
                <Link href={getSignupUrl()} css={primaryButtonStyles}>
                  Sign up now!
                </Link>
              </Box>
            </Box>

            <Box
              width="1px"
              backgroundColor="#c9c9c9"
              display={{
                _: 'none',
                md: 'block',
              }}
            />

            <Box
              flexBasis="50%"
              p={{
                _: '30px',
                md: '54px',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box>
                <Text fontSize="20px" fontWeight="500">
                  What you get:
                </Text>

                <ul
                  css={css`
                    margin-top: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  `}
                >
                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>✅</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      Chatting between users
                    </Box>
                  </li>

                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>✅</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      Chatting in groups
                    </Box>
                  </li>

                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>✅</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      AI-powered assistants
                    </Box>
                  </li>

                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>✅</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      Email notifications
                    </Box>
                  </li>

                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>✅</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      No user or usage limits
                    </Box>
                  </li>

                  <li
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <Box>🔜</Box>

                    <Box
                      css={(theme: Theme) => css`
                        font-size: 18px;
                        line-height: 30px;

                        @media (min-width: ${theme.breakpoints.md}) {
                          font-size: 20px;
                        }
                      `}
                    >
                      Mobile &amp; Desktop app
                    </Box>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        maxWidth="1100px"
        m="0 auto"
        className={colfax.className}
        p={{
          _: '50px 24px',
          md: '50px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          css={css`
            text-align: center;
          `}
        >
          <Box
            css={css`
              font-weight: 500;
              line-height: 46px;
            `}
          >
            <Text
              fontSize={{
                _: '30px',
                md: '48px',
              }}
              fontWeight={500}
              lineHeight="1.10714286"
              css={css`
                letter-spacing: -2px;
              `}
            >
              Teamwork
              <br /> for the best work
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            ml={{
              _: '-20px',
              md: '0',
            }}
            mr={{
              _: '-20px',
              md: '0',
            }}
            mt={{
              _: '-8px',
              md: '0',
            }}
          >
            <Image
              src={chatListImg}
              alt="App List"
              width={590}
              css={css`
                max-width: 100%;
                height: auto;
              `}
              quality={100}
              priority
            />

            <Box width="160px" display="flex" justifyContent="center" py={8}>
              <Link href={getSignupUrl()} css={primaryButtonStyles}>
                Use for free
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box as="footer" display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          css={css`
            justify-content: center;
            margin: 0rem auto 0 auto;
            position: relative;
            gap: 2rem;
            flex-wrap: wrap;
          `}
          py={4}
          p={{
            _: '50px 24px',
            md: '50px',
          }}
        >
          <Box maxWidth={1280}>
            <span
              css={css`
                font-size: 12px;
                line-height: 1.333;
              `}
            >
              We use cookies and similar technologies to enhance your
              interactions with our website and Services, including when you
              reach out to us on chat. This comprises traffic analysis,
              delivering personalized content, and supporting our marketing
              efforts. By accessing our website, interacting with our Services,
              you agree to let us and our partners employ cookies and related
              technologies on your computer or devices. Click the{' '}
              <b>
                <a
                  href="https://www.livechat.com/legal/cookies-policy"
                  target="_blank"
                >
                  Cookies Policy
                </a>
              </b>{' '}
              to check how you can control the use of cookies through your
              device. To understand how we process your data, including through
              cookies, and interactions with us, please read our{' '}
              <b>
                <a
                  href="https://www.livechat.com/legal/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </b>
              . Here you can find our{' '}
              <b>
                <a
                  href="https://www.livechat.com/legal/terms-of-use/"
                  target="_blank"
                >
                  Terms of use
                </a>
              </b>
              .
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
